<template>
  <section id="menu" class="menu">
    <div class="container">
      <div class="content_wrapper">
        <h2 class="section_title">{{ $t('menu.title') }}</h2>
        <a
          class="btn-download"
          href="https://assets.ducha66.pl/Ducha66/menu.pdf"
          target="_blank"
          >{{ $t('menu.download') }}</a
        >
        <div class="flex_wrapper">
          <p class="section_text section_text_right">
            {{ $t('menu.content') }}
          </p>
          <img src="../assets/jpg/perfect_menu_1.jpg" alt="Menu" />
        </div>

        <div class="absolute_text">
          <div class="moving_text">
            <p v-html="$t('menu.animateText')"></p>
            <p v-html="$t('menu.animateText')"></p>
          </div>
        </div>
        <div class="absolute_img">
          <img src="../assets/jpg/perfect_menu_2.jpg" alt="Sample dish" />

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MenuSection',
};
</script>

<style scoped lang="scss">
@import '../scss/menu.scss';
@import '../scss/animation.scss';
</style>
