<template>
  <header class="header">
    <div v-show="windowWidth > 800">
      <nav ref="nav" v-if="!scrolled">
        <div class="nav_start container">
          <ul class="nav">
            <li
              class="nav_item1"
              @mouseover="hideIcon1 = false"
              @mouseleave="hideIcon1 = true"
            >
              <a href="#menu">
                <span v-if="hideIcon1" class="item-1">
                  {{ $t('nav.menu') }}</span
                >
                <span v-else>
                  <svg
                    class="menu_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 77.97 54.01"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path
                          class="cls-1"
                          d="M39,29.2v22A1.67,1.67,0,0,1,37.47,53h-35A1.67,1.67,0,0,1,1,51.24V27.81A1.67,1.67,0,0,1,2.49,26H5.13"
                        />
                        <path
                          class="cls-1"
                          d="M37.47,53,11.15,28c-1.14-1-1.51-1.5-1.51-2.48V2.76A1.67,1.67,0,0,1,11.15,1L37.47,26c1.7,1.64,1.51,2.2,1.51,3.18v22A1.67,1.67,0,0,1,37.47,53h-35A1.67,1.67,0,0,1,1,51.24V27.81A1.67,1.67,0,0,1,2.49,26H5.13"
                        />
                        <path
                          class="cls-2"
                          d="M36.51,52.27c0,.06-.12.07-.12.07L6.25,38.56a2,2,0,0,1-1.51-1.79V13.33a1.68,1.68,0,0,1,1.51-1.79l3,1.37L9.17,25.09S9,26.53,10,26.89L36.51,52.15A.21.21,0,0,1,36.51,52.27Z"
                        />
                        <path
                          class="cls-1"
                          d="M39,29.2v22A1.67,1.67,0,0,0,40.5,53h35A1.67,1.67,0,0,0,77,51.24V27.81A1.67,1.67,0,0,0,75.48,26H72.84"
                        />
                        <path
                          class="cls-1"
                          d="M40.5,53,66.82,28c1.14-1,1.52-1.5,1.52-2.48V2.76A1.67,1.67,0,0,0,66.82,1L40.5,26C38.8,27.66,39,28.22,39,29.2v22A1.67,1.67,0,0,0,40.5,53h35A1.67,1.67,0,0,0,77,51.24V27.81A1.67,1.67,0,0,0,75.48,26H72.84"
                        />
                        <path
                          class="cls-2"
                          d="M41.46,52.27c0,.06.12.07.12.07L71.72,38.56a2,2,0,0,0,1.52-1.79V13.33a1.68,1.68,0,0,0-1.52-1.79l-3,1.37.08,12.18s.21,1.44-.79,1.8L41.47,52.15A.17.17,0,0,0,41.46,52.27Z"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </a>
            </li>

            <li
              class="nav_item2"
              @mouseover="hideIcon2 = false"
              @mouseleave="hideIcon2 = true"
            >
              <a href="#karta-win">
                <span v-if="hideIcon2" class="item-2">
                  {{ $t('nav.wine&distillates') }}</span
                >
                <span v-else>
                  <svg
                    class="menu_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 31.92 68.73"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path
                          class="cls-1"
                          d="M16,41.19h0A14.81,14.81,0,0,1,1.2,26L3.39,1.2h25l2.32,24.73A14.81,14.81,0,0,1,16,41.19Z"
                        />
                        <path
                          d="M16,37.14A10.58,10.58,0,0,1,5.38,26.39l.49-5.53A23.31,23.31,0,0,0,16,19.17a21.71,21.71,0,0,1,9.76-1.63l.82,8.79A10.59,10.59,0,0,1,16,37.14"
                        />
                        <line
                          class="cls-1"
                          x1="15.96"
                          y1="42.41"
                          x2="15.96"
                          y2="66.68"
                        />
                        <line
                          class="cls-2"
                          x1="7.85"
                          y1="67.69"
                          x2="24.08"
                          y2="67.69"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </a>
            </li>

            <li
              class="nav_item3"
              @mouseover="hideIcon3 = false"
              @mouseleave="hideIcon3 = true"
            >
              <a
                  href="#banner">
                <span v-if="hideIcon3" class="item-3">
                  {{ $t('nav.occasionalMenu') }}</span
                >
                <span v-else>
                  <svg
                      class="menu_icon"
                       xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 61.1 68.7">
                    <g id="Layer_1-2">
                      <path class="cls-1" d="M24.44,12.41l5.33,15.45c1.3,3.78,4.25,6.77,8.01,8.13l7.56,2.73-7.56,2.73c-3.76,1.36-6.71,4.34-8.01,8.13l-5.33,15.45-5.33-15.45c-1.3-3.78-4.25-6.77-8.01-8.13l-7.56-2.73,7.56-2.73c3.76-1.36,6.71-4.34,8.01-8.13l5.33-15.45Z"/>
                      <path class="cls-2" d="M47.28,2.51l2.91,8.43c.71,2.06,2.32,3.69,4.37,4.43l4.13,1.49-4.13,1.49c-2.05,.74-3.66,2.37-4.37,4.43l-2.91,8.43-2.91-8.43c-.71-2.06-2.32-3.69-4.37-4.43l-4.13-1.49,4.13-1.49c2.05-.74,3.66-2.37,4.37-4.43l2.91-8.43Z"/>
                    </g></svg>
                </span>
              </a>
            </li>

            <li class="nav_item4">
              <img
                alt="Big logo"
                class="logo-main"
                src="../assets/png/logo_start.png"
              />
            </li>
            <li
                class="nav_item5"
                @mouseover="hideIcon4 = false"
                @mouseleave="hideIcon4 = true"
            >
              <a href="#poznaj-ducha">
                <span v-if="hideIcon4" class="item-3">
                  {{ $t('nav.meetSpirit') }}</span
                >
                <span v-else>
                  <svg
                      class="menu_icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 50.73 69.57"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path
                            class="cls-1"
                            d="M1.33,64.75l4.2-40.63c0-.18,0-.37,0-.55a1.34,1.34,0,0,1,0-.2v-.06C6.22,11,13.91,1.2,25.37,1.2S44.51,11,45.16,23.31c0,0,0,0,0,.06a1.34,1.34,0,0,0,0,.2c0,.18,0,.37,0,.55l4.2,40.63c-6.42,0-5.59,3.62-12,3.62s-5.59-3.62-12-3.62-5.59,3.62-12,3.62S7.76,64.75,1.33,64.75Z"
                        />
                        <circle cx="18.01" cy="22.86" r="5.26" />
                        <circle cx="32.72" cy="22.86" r="5.26" />
                      </g>
                    </g>
                  </svg>
                </span>
              </a>
            </li>
            <li
              class="nav_item6"
              @mouseover="hideIcon5 = false"
              @mouseleave="hideIcon5 = true"
            >
              <a href="#galeria">
                <span v-if="hideIcon5" class="item-5">
                  {{ $t('nav.gallery') }}</span
                >
                <span v-else>
                  <svg
                    class="menu_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 69.36 54.52"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <rect
                          class="cls-1"
                          x="1.1"
                          y="7.78"
                          width="67.17"
                          height="45.64"
                          rx="10.95"
                        />
                        <circle class="cls-2" cx="34.68" cy="30.6" r="15.67" />
                        <circle cx="34.68" cy="30.6" r="11.01" />
                        <rect
                          class="cls-1"
                          x="7.99"
                          y="1.1"
                          width="11.29"
                          height="6.69"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </a>
            </li>

            <li
              class="nav_item7"
              @mouseover="hideIcon6 = false"
              @mouseleave="hideIcon6 = true"
            >
              <a href="#kontakt">
                <span v-if="hideIcon6" class="item-5">
                  {{ $t('nav.contact') }}</span
                >
                <span v-else>
                  <svg
                    class="menu_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 79.51 59.41"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <circle cx="24.82" cy="25.62" r="5.26" />
                        <circle cx="39.75" cy="25.62" r="5.26" />
                        <circle cx="54.69" cy="25.62" r="5.26" />
                        <path
                          class="cls-1"
                          d="M39.75,1.2C18.46,1.2,1.2,12.61,1.2,26.69a19.22,19.22,0,0,0,5,12.55v0L5.32,57.44,22,49.34h0a55,55,0,0,0,17.7,2.85c21.3,0,38.56-11.42,38.56-25.49S61.05,1.2,39.75,1.2Z"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </a>
            </li>
          </ul>
        </div>

        <div class="container">
          <div class="flex">
            <h1 class="page_title">{{ msg }}</h1>
            <LocaleSwitcher />
          </div>
        </div>
      </nav>

      <nav v-else class="fixed">
        <ul class="nav">
          <li class="nav_item">
            <LocaleSwitcher />
          </li>

          <div class="fixed-nav-right">
            <li
              class="nav_item"
              @mouseover="hideIcon1 = false"
              @mouseleave="hideIcon1 = true"
            >
              <a href="#menu">
                <span v-if="hideIcon1" class="item-1">
                  {{ $t('nav.menu') }}</span
                >
                <span v-else>
                  <svg
                    class="menu_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 77.97 54.01"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path
                          class="cls-1"
                          d="M39,29.2v22A1.67,1.67,0,0,1,37.47,53h-35A1.67,1.67,0,0,1,1,51.24V27.81A1.67,1.67,0,0,1,2.49,26H5.13"
                        />
                        <path
                          class="cls-1"
                          d="M37.47,53,11.15,28c-1.14-1-1.51-1.5-1.51-2.48V2.76A1.67,1.67,0,0,1,11.15,1L37.47,26c1.7,1.64,1.51,2.2,1.51,3.18v22A1.67,1.67,0,0,1,37.47,53h-35A1.67,1.67,0,0,1,1,51.24V27.81A1.67,1.67,0,0,1,2.49,26H5.13"
                        />
                        <path
                          class="cls-2"
                          d="M36.51,52.27c0,.06-.12.07-.12.07L6.25,38.56a2,2,0,0,1-1.51-1.79V13.33a1.68,1.68,0,0,1,1.51-1.79l3,1.37L9.17,25.09S9,26.53,10,26.89L36.51,52.15A.21.21,0,0,1,36.51,52.27Z"
                        />
                        <path
                          class="cls-1"
                          d="M39,29.2v22A1.67,1.67,0,0,0,40.5,53h35A1.67,1.67,0,0,0,77,51.24V27.81A1.67,1.67,0,0,0,75.48,26H72.84"
                        />
                        <path
                          class="cls-1"
                          d="M40.5,53,66.82,28c1.14-1,1.52-1.5,1.52-2.48V2.76A1.67,1.67,0,0,0,66.82,1L40.5,26C38.8,27.66,39,28.22,39,29.2v22A1.67,1.67,0,0,0,40.5,53h35A1.67,1.67,0,0,0,77,51.24V27.81A1.67,1.67,0,0,0,75.48,26H72.84"
                        />
                        <path
                          class="cls-2"
                          d="M41.46,52.27c0,.06.12.07.12.07L71.72,38.56a2,2,0,0,0,1.52-1.79V13.33a1.68,1.68,0,0,0-1.52-1.79l-3,1.37.08,12.18s.21,1.44-.79,1.8L41.47,52.15A.17.17,0,0,0,41.46,52.27Z"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </a>
            </li>
            <li
              class="nav_item"
              @mouseover="hideIcon2 = false"
              @mouseleave="hideIcon2 = true"
            >
              <a href="#karta-win">
                <span v-if="hideIcon2" class="item-2">
                  {{ $t('nav.wine&distillates') }}</span
                >
                <span v-else>
                  <svg
                    class="menu_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 31.92 68.73"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path
                          class="cls-1"
                          d="M16,41.19h0A14.81,14.81,0,0,1,1.2,26L3.39,1.2h25l2.32,24.73A14.81,14.81,0,0,1,16,41.19Z"
                        />
                        <path
                          d="M16,37.14A10.58,10.58,0,0,1,5.38,26.39l.49-5.53A23.31,23.31,0,0,0,16,19.17a21.71,21.71,0,0,1,9.76-1.63l.82,8.79A10.59,10.59,0,0,1,16,37.14"
                        />
                        <line
                          class="cls-1"
                          x1="15.96"
                          y1="42.41"
                          x2="15.96"
                          y2="66.68"
                        />
                        <line
                          class="cls-2"
                          x1="7.85"
                          y1="67.69"
                          x2="24.08"
                          y2="67.69"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </a>
            </li>

            <li
                class="nav_item nav_item_occasional"
                @mouseover="hideIcon4 = false"
                @mouseleave="hideIcon4 = true"
            >
              <a
                  href="#banner">
                <span v-if="hideIcon4" class="item-3">
                  {{ $t('nav.occasionalMenu') }}</span
                >
                <span v-else>
                  <svg
                      class="menu_icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 61.1 68.7">
                    <g id="Layer_1-2">
                      <path class="cls-1" d="M24.44,12.41l5.33,15.45c1.3,3.78,4.25,6.77,8.01,8.13l7.56,2.73-7.56,2.73c-3.76,1.36-6.71,4.34-8.01,8.13l-5.33,15.45-5.33-15.45c-1.3-3.78-4.25-6.77-8.01-8.13l-7.56-2.73,7.56-2.73c3.76-1.36,6.71-4.34,8.01-8.13l5.33-15.45Z"/>
                      <path class="cls-2" d="M47.28,2.51l2.91,8.43c.71,2.06,2.32,3.69,4.37,4.43l4.13,1.49-4.13,1.49c-2.05,.74-3.66,2.37-4.37,4.43l-2.91,8.43-2.91-8.43c-.71-2.06-2.32-3.69-4.37-4.43l-4.13-1.49,4.13-1.49c2.05-.74,3.66-2.37,4.37-4.43l2.91-8.43Z"/>
                    </g></svg>
                </span>
              </a>
            </li>

            <li
              class="nav_item"
              @mouseover="hideIcon3 = false"
              @mouseleave="hideIcon3 = true"
            >
              <a href="#poznaj-ducha">
                <span v-if="hideIcon3" class="item-3">
                  {{ $t('nav.meetSpirit') }}</span
                >
                <span v-else>
                  <svg
                    class="menu_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50.73 69.57"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path
                          class="cls-1"
                          d="M1.33,64.75l4.2-40.63c0-.18,0-.37,0-.55a1.34,1.34,0,0,1,0-.2v-.06C6.22,11,13.91,1.2,25.37,1.2S44.51,11,45.16,23.31c0,0,0,0,0,.06a1.34,1.34,0,0,0,0,.2c0,.18,0,.37,0,.55l4.2,40.63c-6.42,0-5.59,3.62-12,3.62s-5.59-3.62-12-3.62-5.59,3.62-12,3.62S7.76,64.75,1.33,64.75Z"
                        />
                        <circle cx="18.01" cy="22.86" r="5.26" />
                        <circle cx="32.72" cy="22.86" r="5.26" />
                      </g>
                    </g>
                  </svg>
                </span>
              </a>
            </li>

            <li
              class="nav_item"
              @mouseover="hideIcon5 = false"
              @mouseleave="hideIcon5 = true"
            >
              <a href="#galeria">
                <span v-if="hideIcon5" class="item-5">
                  {{ $t('nav.gallery') }}</span
                >
                <span v-else>
                  <svg
                    class="menu_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 69.36 54.52"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <rect
                          class="cls-1"
                          x="1.1"
                          y="7.78"
                          width="67.17"
                          height="45.64"
                          rx="10.95"
                        />
                        <circle class="cls-2" cx="34.68" cy="30.6" r="15.67" />
                        <circle cx="34.68" cy="30.6" r="11.01" />
                        <rect
                          class="cls-1"
                          x="7.99"
                          y="1.1"
                          width="11.29"
                          height="6.69"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </a>
            </li>

            <li
              class="nav_item"
              @mouseover="hideIcon6 = false"
              @mouseleave="hideIcon6 = true"
            >
              <a href="#kontakt">
                <span v-if="hideIcon6" class="item-5">
                  {{ $t('nav.contact') }}</span
                >
                <span v-else>
                  <svg
                    class="menu_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 79.51 59.41"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <circle cx="24.82" cy="25.62" r="5.26" />
                        <circle cx="39.75" cy="25.62" r="5.26" />
                        <circle cx="54.69" cy="25.62" r="5.26" />
                        <path
                          class="cls-1"
                          d="M39.75,1.2C18.46,1.2,1.2,12.61,1.2,26.69a19.22,19.22,0,0,0,5,12.55v0L5.32,57.44,22,49.34h0a55,55,0,0,0,17.7,2.85c21.3,0,38.56-11.42,38.56-25.49S61.05,1.2,39.75,1.2Z"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </a>
            </li>
            <li class="nav_item">
              <a href="#">
                <img
                  alt="Small logo"
                  class="logo-main"
                  src="../assets/png/logo_start.png"
                />
              </a>
            </li>
          </div>
        </ul>
        <a href="#" id="customID" class="up_button"></a>
      </nav>
    </div>

    <div v-show="windowWidth <= 800">
      <nav ref="nav" v-if="!scrolled">
        <div class="nav_start container">
          <img
            alt="Big logo"
            class="logo-main"
            src="../assets/png/logo_start.png"
          />

          <h1 class="page_title">{{ msg }}</h1>
          <input class="menu-btn" type="checkbox" id="menu-btn" />
          <label class="menu-icon" for="menu-btn"
            ><span class="navicon"></span
          ></label>

          <div class="menu">
            <div class="nav_items_wrap">
              <ul>
                <li class="nav_item">
                  <a href="#menu" v-on:click="uncheck">
                    <span class="item"> {{ $t('nav.menu') }}</span>
                  </a>
                </li>

                <li class="nav_item">
                  <a href="#karta-win" v-on:click="uncheck">
                    <span class="item"> {{ $t('nav.wine&distillates') }}</span>
                  </a>
                </li>
                <li class="nav_item">
                  <a
                      href="#banner">
                    <span class="item">{{ $t('nav.occasionalMenu') }}</span>
                  </a>
                </li>
                <li class="nav_item">
                  <a href="#poznaj-ducha" v-on:click="uncheck">
                    <span class="item"> {{ $t('nav.meetSpirit') }}</span>
                  </a>
                </li>

                <li class="nav_item">
                  <a href="#galeria" v-on:click="uncheck">
                    <span class="item"> {{ $t('nav.gallery') }}</span>
                  </a>
                </li>

                <li class="nav_item">
                  <a href="#kontakt" v-on:click="uncheck">
                    <span class="item"> {{ $t('nav.contact') }}</span>
                  </a>
                </li>

                <LocaleSwitcher />
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <nav v-else class="fixed">
        <div class="nav_start-flex container">
          <input class="menu-btn" type="checkbox" id="menu-btn" />
          <label class="menu-icon" for="menu-btn" id="menuIcon"
            ><span class="navicon"></span
          ></label>
          <div class="menu">
            <a href="#"
              ><h1 class="page_title menu_fixed-title" v-on:click="uncheck">
                {{ msg }}
              </h1></a
            >
            <ul>
              <li class="nav_item">
                <a href="#menu" v-on:click="uncheck">
                  <span class="item"> {{ $t('nav.menu') }}</span>
                </a>
              </li>

              <li class="nav_item">
                <a href="#karta-win" v-on:click="uncheck">
                  <span class="item"> {{ $t('nav.wine&distillates') }}</span>
                </a>
              </li>

              <li class="nav_item">
                <a
                    href="#banner"
                     v-on:click="uncheck">
                  <span class="item">{{ $t('nav.occasionalMenu') }}</span>
                </a>
              </li>

              <li class="nav_item">
                <a href="#poznaj-ducha" v-on:click="uncheck">
                  <span class="item"> {{ $t('nav.meetSpirit') }}</span>
                </a>
              </li>

              <li class="nav_item">
                <a href="#galeria" v-on:click="uncheck">
                  <span class="item"> {{ $t('nav.gallery') }}</span>
                </a>
              </li>

              <li class="nav_item">
                <a href="#kontakt" v-on:click="uncheck">
                  <span class="item-5"> {{ $t('nav.contact') }}</span>
                </a>
              </li>

              <LocaleSwitcher />
            </ul>
          </div>
        </div>
        <a href="#" class="up_button"></a>
      </nav>
    </div>

    <div id="video-absolute" class="container">
      <div class="video-container">
        <video
          title="Steak preparation video"
          controls
          id="video"
          preload="metadata"
          poster="../assets/jpg/video_start_poster.jpg"
          v-play="playing"
          autoplay="true"
          muted="muted"
        >
          <source src="../assets/movies/film_stek.mp4" type="video/mp4" />
          Your browser does not support HTML video.
        </video>
        <div class="play-button-wrapper">
          <div
            title="Play video"
            class="play-gif"
            id="circle-play-b"
            @click="tooglePlay"
            v-bind:class="{
              hide: this.playing,
            }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89 89">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <circle class="cls-1" cx="44.5" cy="44.5" r="42.5" />
                  <polygon
                    class="cls-1 cls-1b"
                    points="63.34 43.79 34.51 60.86 34.15 27.36 63.34 43.79"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<style scoped>
li {
  width: 100%;
}
</style>
<script>
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
export default {
  name: 'HeaderComponent',
  components: {
    LocaleSwitcher,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      playing: false,
      hideIcon1: true,
      hideIcon2: true,
      hideIcon3: true,
      hideIcon4: true,
      hideIcon5: true,
      hideIcon6: true,
      scrolled: false,
      windowWidth: 0,
    };
  },
  created() {
    document.addEventListener('scroll', this.scroll);
  },
  mounted() {
    this.offsetHeight = this.$refs.nav.offsetHeight;
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    });

  },
  computed: {
    paused() {
      return !this.playing;
    },
  },

  methods: {
    tooglePlay() {
      this.playing = !this.playing;
    },

    scroll() {
      if (window.scrollY >= this.offsetHeight) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    uncheck: function () {
      document.getElementById('menu-btn').checked = false;
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.getWindowWidth);
  },

  directives: {
    play: {
      bind(el, binding, vnode) {
        el.addEventListener('playing', () => {
          vnode.context[binding.expression] = !el.paused;
        });
        el.addEventListener('pause', () => {
          vnode.context[binding.expression] = !el.paused;
        });
        vnode.context[binding.expression] = !el.paused;
      },
      update(el, binding) {
        if (el.paused) {
          if (binding.value) {
            el.play();
          }
        } else if (!binding.value) {
          el.pause();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import '../scss/home.scss';
</style>
