<template>
  <section id="karta-win" class="wine">
    <div class="container">
      <div class="content_wrapper">
        <h2 class="section_title">{{ $t('wine.title') }}</h2>
        <a
          class="btn-download"
          href="/pdf/karta_wina_destylaty.pdf"
          target="_blank"
          v-html="$t('wine.download')"
          ></a
        >
        <div class="flex_wrapper">
          <div>
            <p class="section_text">{{ $t('wine.content') }}</p>
            <p class="section_text">{{ $t('wine.content2') }}</p>
          </div>
          <img src="../assets/jpg/give_wine_1.jpg" alt="Glass of wine" />
        </div>
        <div class="absolute_text">
          <div class="wrapper">
            <div class="moving_text">
              <p v-html="$t('wine.animateText')"></p>
              <p v-html="$t('wine.animateText')"></p>
              <p v-html="$t('wine.animateText')"></p>
              <p v-html="$t('wine.animateText')"></p>
            </div>
          </div>
        </div>
        <div class="absolute_img">
          <img src="../assets/jpg/give_wine_2.jpg" alt="Bottle of wine" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WineSection',
};
</script>

<style scoped lang="scss">
@import '../scss/wine.scss';
@import '../scss/animation.scss';
</style>
