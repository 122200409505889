<template>
  <section id="galeria">
    <div class="container">
      <div class="content_wrapper">
        <h2 class="section_title text_right">
          {{ $t('gallery.title') }}
        </h2>
        <p class="section_text text_right">{{ $t('gallery.content') }}</p>
      </div>

      <div class="carousel-gallery">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(image, i) in images" :key="i">
              <a :href="image" data-fancybox="gallery">
                <div
                  class="image"
                  :style="{
                    backgroundImage: `url(${image})`,
                  }"
                >
                  <div class="overlay">
                    <em class="mdi mdi-magnify-plus"></em>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import $ from 'jquery';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

export default {
  name: 'GalleryComponent',

  data() {
    return {
      images: [
        require('@/assets/img/new_gallery/img_01.jpg'),
        require('@/assets/img/new_gallery/img_02.jpg'),
        require('@/assets/img/new_gallery/img_03.jpg'),
        require('@/assets/img/new_gallery/img_04.jpg'),
        require('@/assets/img/new_gallery/img_05.jpg'),
        require('@/assets/img/new_gallery/img_06.jpg'),
        require('@/assets/img/new_gallery/img_07.jpg'),
        require('@/assets/img/new_gallery/img_08.jpg'),
        require('@/assets/img/new_gallery/img_09.jpg'),
        require('@/assets/img/new_gallery/img_10.jpg'),
        require('@/assets/img/new_gallery/img_11.jpg'),
        require('@/assets/img/new_gallery/img_12.jpg'),
        require('@/assets/img/new_gallery/img_13.jpg'),
        require('@/assets/img/new_gallery/img_14.jpg'),
        require('@/assets/img/new_gallery/img_15.jpg'),
      ],
      index: null,
    };
  },
};

Swiper.use([Navigation, Pagination, Autoplay]);

$(function () {
  new Swiper('.carousel-gallery .swiper-container', {
    effect: 'slide',
    speed: 900,
    slidesPerView: 5,
    spaceBetween: 5,
    simulateTouch: true,
    observer: true,
    observeParents: true,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.carousel-gallery .swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 5,
      },
      425: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
    },
  });
});
</script>

<style scoped lang="scss">
@import '../../node_modules/swiper/swiper-bundle.min.css';
@import '../scss/gallery.scss';
</style>
