<template>
  <div class="lang">
    <span
      v-for="locale in langs"
      :key="locale.key"
      @click="[switchLocale(locale.name), select(locale.key)]"
      :id="locale.name"
      v-bind:class="{
        selected: locale.isSelected,
      }"
    >
      {{ $t(locale.name) }}</span
    >
  </div>
</template>

<script>
import { Trans } from '@/plugins/Translation';
import $ from 'jquery';
export default {
  name: 'LocaleSwitcher',
  data() {
    return {
      langs: [
        { key: '0', name: 'pl', isSelected: this.$i18n.locale == 'pl' },
        { key: '1', name: 'en', isSelected: this.$i18n.locale == 'en' },
      ],
    };
  },
  mounted() {
    if (window.location.pathname.match('/pl/')) {
      this.$i18n.locale = 'pl';
      $('#en').removeClass('selected');
      $('#pl').addClass('selected');
    } else if (window.location.pathname.match('/en/')) {
      this.$i18n.locale = 'en';
      $('#pl').removeClass('selected');
      $('#en').addClass('selected');
    }
  },
  computed: {
    supportedLocales() {
      return Trans.supportedLocales;
    },
  },

  methods: {
    switchLocale(locale) {
      console.log('switch locale', locale);
      if (this.$i18n.locale !== locale) {
        $('#' + 'locale').removeClass('selected');
        const to = this.$router.resolve({ params: { locale } });
        return Trans.changeLocale(locale).then(() => {
          this.$router.push(to.location);
        });
      }
    },

    select(key) {
      for (let i = 0; i < this.langs.length; i++) {
        if (this.langs[i].key !== key) {
          this.langs[i].isSelected = false;
        }
      }

      this.toggleSelection(key);
    },
    toggleSelection(key) {
      const stepsItem = this.langs.find(item => item.key === key);
      if (stepsItem) {
        stepsItem.isSelected = !stepsItem.isSelected;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../scss/home.scss';
</style>
