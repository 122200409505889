<template>
  <section id="poznaj-ducha" class="spirit">
    <div class="container">
      <div class="content_wrapper">
        <h2 class="section_title text_right">{{ $t('spirit.title') }}</h2>
        <a href="#kontakt" class="btn-download">{{ $t('spirit.download') }}</a>
        <div class="flex_wrapper">
          <img
            src="../assets/jpg/meet_spirit_1.jpg"
            alt="Restaurant building"
          />
          <div class="section_text_wrapper">
            <p class="section_text text_right">{{ $t('spirit.content') }}</p>
            <p class="section_text text_right content2">
              {{ $t('spirit.content2') }}
            </p>
          </div>
        </div>
        <div class="flex_wrapper section-two">
          <div class="section_text_wrapper">
            <p class="section_text">{{ $t('spirit.content3') }}</p>
          </div>
          <img src="../assets/jpg/meet_spirit_2.jpg" alt="Dish made by Chef" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SpiritSection',
};
</script>

<style scoped lang="scss">
@import '../scss/spirit.scss';
</style>
