<template>
  <div id="app">
    <header-component :msg="$t('main.title')" />
    <menu-section />
    <wine-section />
    <spirit-section />
    <gallery-component />
    <footer-component />
    <router-view />
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import MenuSection from '@/components/MenuSection.vue';
import WineSection from '@/components/WineSection.vue';
import SpiritSection from '@/components/SpiritSection.vue';
import GalleryComponent from '@/components/GalleryComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import $ from 'jquery';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    MenuSection,
    WineSection,
    SpiritSection,
    GalleryComponent,
    FooterComponent,
  },

  mounted() {
    document.onreadystatechange = () => {
      const locale = this.$i18n.locale;
      const localeToStr = '/' + locale;
      if (document.readyState == 'complete') {
        if (locale == 'pl') {
          const to = this.$router.resolve({ params: { localeToStr } });
          this.$router.push(to).catch(() => {});
        } else {
          const to2 = this.$router.resolve({ params: { localeToStr } });
          this.$router.push(to2).catch(() => {});
        }
      }
    };
  },
  methods: {
    changeLanguage(locale) {
      this.$i18n.locale = locale;
      this.selectedLanguage = locale;
      this.isDropdownOpened = false;
    },
  },
};

$(document).ready(function () {
  if (localStorage.getItem('popState') != 'shown') {
    $('#popup').delay(2000).fadeIn();
    localStorage.setItem('popState', 'shown');
  }

  $('#btnClose').click(function () {
    $('#popup').fadeOut();
  });
  $('#popup').click(function () {
    $('#popup').fadeOut();
  });
});
</script>

<style lang="scss">
@import './scss/main.scss';
</style>
