<template>
  <section id="kontakt" class="footer">
    <div class="container">
      <div class="content_wrapper">
        <h2 class="section_title">{{ $t('contact.title') }}</h2>

        <div class="map_wrapper">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d190.97682018985506!2d18.655073020914404!3d54.34831469924183!2m3!1f0!2f39.491265402254044!3f0!3m2!1i1024!2i768!4f35!3m3!1m2!1s0x46fd73e241e3452b%3A0x5d7f0025dd5edd8f!2zxZp3acSZdGVnbyBEdWNoYSA2NiwgODAtODM0IEdkYcWEc2s!5e1!3m2!1spl!2spl!4v1635337512908!5m2!1spl!2spl"
            width="760"
            height="280"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>

        <div class="flex_wrapper">
          <div class="section_text_wrapper">
            <p class="section_text">{{ $t('contact.content1a') }}</p>
            <p class="section_text" v-html="$t('contact.content1b')"></p>
          </div>
          <div class="section_text_wrapper">
            <p class="section_text">{{ $t('contact.content2a') }}</p>
            <p class="section_text" v-html="$t('contact.content2b')"></p>
          </div>
          <div class="section_text_wrapper">
            <p class="section_text">{{ $t('contact.content3a') }}</p>
            <div class="open_hours_wrap">
              <p class="section_text open-hours">
                <span> {{ $t('contact.content4a') }} </span>
                <span> {{ $t('contact.content5a') }} </span>
              </p>
              <p class="section_text open-hours">
                <span> {{ $t('contact.content4b') }} </span>

                <span> {{ $t('contact.content5b') }} </span>
              </p>
            </div>
          </div>
        </div>
        <div class="social_icons">
          <a href="https://www.facebook.com/ducha.66/" target="_blank"
            ><img src="../assets/png/fb_button.png" alt="Facebook icon"
          /></a>
          <a href="https://www.instagram.com/ducha.66/" target="_blank">
            <img src="../assets/png/ig_button.png" alt="Instagram icon"
          /></a>
          <i class="fas fa-wine-glass-alt"></i>
        </div>
      </div>
    </div>
    <div class="footer_belt">
      <p>{{ $t('contact.copyright') }}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FooterComponent',

  computed: {
    libText: function (arg) {
      var str = arg;
      return str;
    },
  },
  methods: {
    htmlToText: function (html) {
      this.utilityEl.innerHTML = html;
      return this.utilityEl.textContent;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../scss/footer.scss';
</style>
